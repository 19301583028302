<template>
  <div class="section">
    <p class="title has-text-centered">
      Hashtags Overview
    </p>
    <p class="has-text-centered is-size-7 has-text-dark has-margin-bottom-20">
      datas are refreshed daily around 6am GMT
    </p>
    <div class="columns is-centered">
      <div class="column is-half has-text-centered">
        <b-button
          v-if="canAddSearch"
          type="is-primary"
          icon-right="plus-thick"
          outlined
          tag="router-link"
          to="/new/hashtag"
          icon-left="text-box-plus-outline"
          rounded
        >
          Add a hashtag
        </b-button>
        <b-button
          v-else
          type="is-primary"
          icon-right="plus-thick"
          outlined
          disabled
          icon-left="text-box-plus-outline"
          rounded
        >
          Add a hashtag
        </b-button>
      </div>
    </div>
    <HashtagTable v-if="orderedHashtag.length > 0" />

    <div v-if="orderedHashtag.length === 0">
      <div v-if="canAddSearch" class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../assets/empty.svg" />
        </div>
      </div>
      <div v-if="user.plan === 'free'" class="section">
        <div class="columns has-text-centered">
          <div class="column">
            <p class="has-text-centered is-size-4 has-text-black container">
              Start your free trial to track hashtags.
            </p>
          </div>
        </div>
        <StripeBilling />
      </div>
    </div>
    <div class="column has-text-centered">
      <b-button
        v-if="canAddSearch"
        size="is-large is-primary"
        tag="router-link"
        to="/new/hashtag"
        outlined
        rounded
        icon-left="text-box-plus-outline"
      >
        Add a hashtag
      </b-button>
      <div v-else>
        <b-button
          size="is-large is-primary"
          disabled
          outlined
          rounded
          icon-left="text-box-plus-outline"
        >
          Add a hashtag
        </b-button>
        <div class="has-text-danger has-text-weight-light is-size-7">
          <router-link to="setting">
            Upgrade </router-link
          >&nbsp;your account to track hashtag.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HashtagTable from "@/components/hashtag/HashtagTable";
import StripeBilling from "@/components/stripe/StripeBilling";

export default {
  components: { HashtagTable, StripeBilling },

  computed: {
    orderedHashtag() {
      const arr = this.tiktok.slice();
      const filtered = arr.filter((value, index, arr) => value.type === "hashtag");
      filtered.sort((a, b) =>
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        a.challenge_name.localeCompare(b.challenge_name)
      );
      return filtered;
    },
    canAddSearch() {
      if (this.tiktok.length < this.user.limit && this.user.plan !== "free") {
        return true;
      }
      return false;
    },
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
  },
  mounted() {
    this.$amplitude.logEvent("Hastags dashboard");
    this.$store.dispatch("tiktok/getTiktok");
  },
};
</script>

<style>
.svg-custom {
  max-height: 300px;
}
</style>
